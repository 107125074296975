import React from "react"
import "normalize.css"
import "./layout.css"

const Layout: React.FC = ({ children }) => (
  <>
    <main>{children}</main>
    <footer>
      <img src="https://socommerce.pl/svg/group-white.svg" />
    </footer>
  </>
)

export default Layout
