import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const UsingTypescript: React.FC = () => (
  <Layout>
    <SEO title="Strona główna"></SEO>
    <h1>Home of Decor</h1>
    <p>Stwórz z nami wymarzoną koncepcję</p>
    <p>kontakt@socommerce.pl</p>
  </Layout>
)

export default UsingTypescript
